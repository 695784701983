@import '../../mixins.scss';

.component-product-group-verify-step {
  padding: 16px 0;

  & > div.wrapper {
    gap: 16px;
    flex-wrap: wrap;
    @include flex;
    padding: 0 24px;
  }

  @media (max-width: 479px) {
    & > div.wrapper {
      flex-direction: column;
    }
  }
}
