@import '../../mixins.scss';

.card-list-products-container {
  .card-item {
    position: relative;
    gap: 0 !important;
  }

  h2.card-title {
    margin: 0;
    color: #224a13;
    font-family: 'Oxygen', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .brand {
    margin: 0;
    color: $grey-color;
    font-size: 12px;
    line-height: 16px;
  }

  p {
    margin: 5px 0;
    gap: 5px;
    font-size: 10px;
    line-height: 14px;
    color: $grey-color;
    @include flex;
    @include align-items(center);

    & > svg {
      color: #898989;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .btn-more-action {
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
