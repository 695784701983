@import '../mixins.scss';

.component-allocation-type {
  font-size: 12px;
  line-height: 16px;
  gap: 2px;
  @include flex;
  @include align-items(center);

  &.send {
    color: #0a7a08;
  }

  &.receive {
    color: #0483c3;
  }
}
