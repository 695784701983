@import '../../mixins.scss';

.tracking-hardware-attachment-container {
  .partner-history-container {
    .content-wrap {
      padding: 0;
      @include box-shadow(none);
    }

    .title-page {
      display: none;
    }
  }
}
