@import '../../mixins.scss';

.partner-information-container {
  .details-wrap {
    gap: 24px;
    flex-wrap: wrap;

    @include flex;

    .content-wrap {
      flex: 1;
      min-width: 350px;

      @media (max-width: 479px) {
        min-width: 100%;
      }
    }
  }
}
