@import '../../mixins.scss';

.role-actions-section {
  width: 100%;
  min-height: 40px;
  border-bottom: 1px solid #e3e3e3;

  &:last-child {
    border-bottom: 0;
  }

  @include flex;
  @include justify-content(space-between);
  @include align-items(center);

  .label-side {
    flex: 1;
    padding-left: 12px;
    color: $grey-color;
    width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Oxygen', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;

    .grey-color {
      color: #c4c4c4;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.24px;
      text-transform: uppercase;
    }
  }

  .actions-side {
    flex: 1 0 auto;
    color: $primary900-color;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    @include flex;
    @include justify-content(space-between);

    span.action-item {
      @include flex;
      @include align-items(center);
      @include justify-content(center);
      width: 134px;

      svg {
        margin-right: 2px;
      }

      span.tooltip-text {
        @media (max-width: 992px) {
          display: none;
        }
      }
    }

    .ant-checkbox-group {
      width: 100%;
      column-gap: 0;
      @include justify-content(space-between);

      label.ant-checkbox-wrapper {
        width: 134px;
        @include justify-content(center);
      }
    }

    .ant-form-item {
      width: 100%;
    }
  }

  &.general-collapse {
    .actions-side {
      width: 100%;
    }

    .ant-form-item-control-input-content {
      @include justify-content(center);
    }
  }

  @media (max-width: 1199px) {
    .actions-side {
      @include justify-content(flex-end);
      .ant-checkbox-group {
        @include justify-content(flex-end);
      }
    }
  }

  @media (max-width: 992px) {
    .actions-side {
      @include justify-content(space-between);
      span.action-item {
        width: 32px;
      }

      .ant-checkbox-group {
        @include justify-content(space-between);
        label.ant-checkbox-wrapper {
          width: 32px;
        }
      }
    }
  }

  @media (max-width: 479px) {
    .label-side {
      width: 130px;
      font-size: 12px;
      line-height: 20px;
    }

    .actions-side {
      @include justify-content(flex-end);
      .ant-checkbox-group {
        @include justify-content(flex-end);
      }
    }
  }

  @media (max-width: 475px) {
    .label-side {
      padding-left: 0;
    }
  }

  @media (max-width: 375px) {
    @include align-items(flex-start);

    flex-direction: column;
    .label-side {
      padding-left: 0;
    }

    .actions-side {
      align-self: flex-end;
    }
  }
}
