@import '../../mixins.scss';

.reset-password-container {
  .form-container {
    margin-left: 16px;
    margin-right: 16px;
  }

  h2.title {
    text-align: center;
    color: #305a20;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.4px;
  }

  &.login-form-container .logo-section {
    margin: 0px auto;
    margin-bottom: 32px;
    width: 150px;

    div {
      height: 40px;
    }

    svg {
      width: 100%;
      max-height: 40px;
    }
  }

  .ant-form-item.password-input.ant-form-item-with-help {
    margin-bottom: 24px;

    @media (max-width: 570px) {
      margin-bottom: 32px;
    }
  }

  .forgot-password-container {
    p {
      margin: 0;
      color: #898989;
      text-align: center;
      font-family: 'Oxygen', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    form {
      margin-top: 32px;
    }

    .FormFooter {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }

  .forgot-password-success-container {
    margin-bottom: 32px;
    p {
      margin: 0;
      text-align: center;
      font-family: 'Oxygen', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      span {
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }
    }
  }

  .back-to-button-wrap {
    text-align: center;
    color: $primary-color;
    font-family: 'Oxygen', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
  }
}
