@import '../../../mixins.scss';

.Polaris-Popover__ContentContainer {
  .Polaris-OptionList-Option__SingleSelectOption {
    padding: 12px;
    &.Polaris-OptionList-Option--select {
      background-color: $primary-50-color;
      color: $grey-color;
    }
  }
}

.product-performance-filter {
  &.ant-select-dropdown {
    .ant-select-item {
      background-color: transparent !important;
      padding-left: 0;
      padding-right: 0;
      font-weight: 400;

      &.ant-select-item-option-active {
        background-color: transparent !important;
      }

      span.anticon.anticon-check {
        display: none;
      }
    }
  }
}

.ant-select {
  .ant-select-selection-overflow-item {
    span.ant-checkbox-inner {
      // display: none;
    }
  }
}
