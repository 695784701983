.fault-report-button-container {
  .ant-modal-header {
    @apply tw-mb-0;
  }

  .ant-modal-title {
    @apply tw-px-6 tw-pt-6;
  }

  .ant-modal-body {
    padding: 24px !important;
  }
}
