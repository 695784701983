@import '../../mixins.scss';

.partner-name-container {
  .wrapper {
    flex-direction: row;

    @include flex;
    @include align-items(center);
  }

  .logo-section {
    margin-right: 8px;
    img {
      width: 64px;
      height: 64px;
    }
  }

  .name-section {
    .name {
      margin-bottom: 4px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    div.status {
      span {
        padding: 4px 8px 4px 20px;
        position: relative;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        background: $pending-bg-color;
        @include border-radius(24px);

        &::before {
          content: '';
          width: 8px;
          height: 8px;
          position: absolute;
          left: 9px;
          top: 7px;
          background: $pending-color;
          @include border-radius(5px);
        }

        &.active {
          background: $active-bg-color;
          &::before {
            background: $active-color;
          }
        }

        &.deactivated {
          background: $inactive-bg-color;
          &::before {
            background: $inactive-color;
          }
        }
      }
    }
  }
}
