.scan-illutration-gallery-container {
  ul.slick-dots.slick-dots-bottom {
    display: inline-flex !important;
    border-radius: 10px;
    @apply tw-bg-grey-400 tw-w-14 tw-h-5 tw-justify-center tw-items-center tw-mx-auto tw-my-0;

    li {
      @apply tw-bg-white tw-min-w-2 tw-max-w-2 tw-min-h-2 tw-max-h-2 tw-rounded-full;

      &.slick-active {
        @apply tw-bg-primary-500 tw-max-w-2;

        button {
          background: none !important;
        }
      }
    }
  }

  .slick-arrow {
    top: 38%;
    padding: 8px;
    color: #fff;
    display: flex;
    background-color: #898989;
    width: 24px;
    height: 24px;
    @apply tw-rounded-full;

    &.slick-prev::after {
      top: 26%;
      left: 38%;
    }

    &.slick-next::after {
      top: 26%;
      left: 20%;
    }
  }
}
