.qr-scanner-component {
  width: 100%;
  height: 333px;

  & > div {
    border-radius: 8px;
    padding-top: 0 !important;
    height: 333px;

    svg {
      border-width: 64px 196px !important;
      height: 333px !important;
      border-color: rgba(0, 0, 0, 0.6) !important;

      path {
        display: none;
      }
    }
    video {
      width: 100%;
      height: 333px !important;
      object-fit: cover !important;
    }
  }

  @media (max-width: 767px) {
    width: 446px;
    margin: 0 auto;

    & > div {
      svg {
        border-width: 60px 110px !important;
        border-color: rgba(0, 0, 0, 0.6) !important;
      }
    }
  }
  @media (max-width: 479px) {
    width: 330px;
    height: 280px;
    margin: 0 auto;

    & > div {
      height: 280px !important;
      svg {
        height: 280px !important;
        border-width: 60px 60px !important;
      }

      video {
        height: 280px !important;
      }
    }
  }

  @media (max-width: 375px) {
    width: 280px;
    height: 280px;
    margin: 0 auto;

    & > div {
      height: 280px !important;
      svg {
        height: 280px !important;
        border-width: 60px 60px !important;
      }

      video {
        height: 280px !important;
      }
    }
  }
}
