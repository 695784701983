@import '../mixins.scss';

.component-empty-data-container {
  width: 100%;
  @include flex;
  @include justify-content(center);

  p {
    margin: 0;
    color: #898989;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.4px;
  }
}
