@import '../../mixins.scss';

span.partner-status {
  padding: 4px 8px 4px 24px;
  position: relative;
  text-transform: capitalize;
  @include border-radius(24px);

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    left: 9px;
    top: 6px;
    @include border-radius(5px);
  }

  &.active {
    background-color: $active-bg-color;
    &::before {
      background: $active-color;
    }
  }

  &.pending {
    background-color: $pending-bg-color;
    &::before {
      background: $pending-color;
    }
  }

  &.idle {
    background-color: $pending-bg-color;
    &::before {
      background: $pending-color;
    }
  }

  &.inactive {
    background-color: $inactive-bg-color;
    &::before {
      background: $inactive-color;
    }
  }
}
