@import '../../mixins.scss';

.component-hardware-register-scan-step {
  .wrapper {
    width: 672px;
    margin: 0 auto;
    padding: 24px;
    margin-bottom: 70px;
  }

  .ant-tabs-nav {
    .ant-tabs-nav-list {
      width: 100%;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid $border-color;
      background: #fff;

      @include flex;
      @include justify-content(space-between);

      .ant-tabs-tab {
        flex: 1;
        margin: 0;
        padding: 6px 12px;
        color: $secondary-text;
        font-size: 14px;
        line-height: 20px;
        font-family: 'Poppins', sans-serif;
        @include justify-content(center);

        &.ant-tabs-tab-active {
          background-color: $primary-50-color;
          @include border-radius(8px);

          .ant-tabs-tab-btn {
            color: #437132;

            span {
              color: $grey-color;
            }
          }
        }

        .ant-tabs-tab-btn {
          flex-direction: column;
          @include flex;
          @include align-items(center);

          svg {
            margin-bottom: 8px;
          }
        }
      }

      .ant-tabs-ink-bar {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    .wrapper {
      width: 100%;
      padding: 24px 0;
    }
  }

  @media (max-width: 479px) {
    .ant-tabs-nav {
      .ant-tabs-nav-list {
        padding: 6px;

        .ant-tabs-tab {
          font-size: 12px;
          .ant-tabs-tab-btn {
            svg {
              width: 18px;
              height: 18px;
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
}
