@import '../mixins.scss';
.Polaris-DatePicker {
  .Polaris-DatePicker__Day {
    &.Polaris-DatePicker__Day--selected {
      background-color: $primary-color;
    }

    &:hover {
      background-color: $primary-color;
    }

    &.Polaris-DatePicker__Day--inRange {
      background-color: $primary-50-color;
    }
  }
}

.Polaris-Box.Polaris-Box--listReset {
  padding: 6px;
  .Polaris-OptionList-Option {
    .Polaris-OptionList-Option__Icon {
      display: none;
    }

    button {
      font-family: 'Oxygen', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      &:hover {
        background-color: $primary-50-color;
        font-weight: 700;
      }

      &.Polaris-OptionList-Option--select {
        font-weight: 700;
        background-color: $primary-50-color;
      }

      &::before {
        display: none;
      }
    }
  }

  .Polaris-OptionList-Option:nth-child(2n),
  .Polaris-OptionList-Option:last-child {
    padding-bottom: 4px;
    border-radius: 0;
  }
  .Polaris-OptionList-Option:nth-child(2n),
  .Polaris-OptionList-Option:last-child {
    border-bottom: 1px solid #ccc;
  }
}
