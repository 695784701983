.barcode-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.barcode-svg {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
