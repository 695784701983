@import '../../mixins.scss';

.upload-attachment-form-container {
  .attachment-category-wrap {
    p {
      margin: 0;
      margin-bottom: 4px;
      color: $grey-color;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .actions {
    @include flex;
    @include justify-content(space-between);
    @include align-items(center);
    gap: 24px;

    button {
      flex: 1;
    }
  }
}
