@import '../../mixins.scss';

.component-scan-tabs-content {
  div.notice-section {
    width: 500px;
    margin: 16px auto;
    color: $grey-color;
    font-size: 12px;
    line-height: 16px;
    gap: 12px;
    @include flex;

    & > svg {
      min-width: 14px;
    }

    ul {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 20px;
    }
  }

  @media (max-width: 767px) {
    p.notice-section {
      width: 100%;
      padding: 0 16px;
    }
  }

  .scanned-btn {
    text-align: center;
    margin-bottom: 16px;

    button {
      background: #fff;
      padding: 12px;
      color: $grey-color;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      border: 1px solid $border-color;

      & > span {
        gap: 8px;
        @include border-radius(8px);
        @include flex;
        @include align-items(center);
      }

      &:hover {
        background: #fff !important;
        border-color: $border-color;
        color: $grey-color !important;
      }

      svg {
        fill: #898989;
      }
    }
  }
}

.hardware-scan-qrcode-drawer {
  .ant-drawer-header-title {
    flex-direction: row-reverse;
    font-size: 14px;

    button.ant-drawer-close {
      margin-right: 0;
    }

    .ant-drawer-title {
      @include flex;
      @include align-items(center);
    }
  }

  .hardware-scanned-list-drawer {
    flex-wrap: wrap;
    gap: 10px;
    @include flex;

    div.item {
      flex: 1;
      width: 136px;
      min-width: 136px;
      max-width: 136px;
      background: #fff;
      padding: 16px 12px;
      @include border-radius(8px);
      @include box-shadow(0px 0px 5px 0px rgba(130, 134, 139, 0.3));

      p {
        margin: 0;
      }

      & > p {
        margin-bottom: 8px;
        font-size: 10px;
        line-height: 14px;
        @include flex;
        @include align-items(center);
        gap: 2;

        & + p {
          color: var(--Primary-Primary-900, #224a13);
          font-family: 'Oxygen', sans-serif;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 0;
        }
      }
    }
  }
}
