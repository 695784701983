@import '../../mixins.scss';

.btn-confirm {
  &.error {
    border: 1px solid $danger-color !important;
    color: $danger-color !important;
  }
}

.confirm-content-wrapper {
  .ant-modal-confirm-body {
    span.anticon {
      display: none;
    }

    h2 {
      margin: 0;
      margin-bottom: 8px;
      color: $grey-color;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.4px;
    }

    p {
      margin: 0;
      color: $grey-color;
      font-family: 'Oxygen', sans-serif;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .ant-modal-confirm-btns {
    flex-direction: column-reverse;
    gap: 12px;

    button {
      margin-left: 0 !important;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      &.ant-btn-primary {
        background-color: $primary-color !important;
        border: 1px solid $primary-color !important;
      }

      &.ant-btn-default {
        border: 1px solid $primary-color !important;
        color: $primary-color !important;
      }
    }
  }

  &.error {
    h2 {
      color: $danger-color;
    }

    .ant-modal-confirm-btns {
      button {
        &.ant-btn-primary {
          background-color: $danger-color !important;
          border: 1px solid $danger-color !important;
        }

        &.ant-btn-default {
          border: 1px solid $danger-color !important;
          color: $danger-color !important;
        }
      }
    }
  }

  &.horizontal {
    .ant-modal-confirm-btns {
      @apply tw-flex tw-flex-row;

      & > button {
        @apply tw-flex-1;
      }

      @media (max-width: 479px) {
        @apply tw-flex-col;
      }
    }
  }

  @media (max-width: 479px) {
    .ant-modal-confirm.ant-modal-confirm-confirm {
      width: 98% !important;
    }

    .ant-modal-confirm-confirm .ant-modal-content {
      min-width: auto;
    }

    .ant-modal-confirm-body {
      h2 {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
