@import '../../../mixins.scss';

.start-scanning-screen-component {
  .button-acton {
    @include flex;
    @include justify-content(center);

    button {
      width: 300px;
      padding-top: 11px;
      padding-bottom: 11px;
    }
  }
}
