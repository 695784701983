@import '../../mixins.scss';

.upload-attachment-button-container {
  border-radius: 6px;
  border: 1px solid #dad9d9;
  padding: 10px;
  width: 100%;

  button.btn-upload-attachment {
    background: #fff;
    width: 100%;
    color: $primary-color;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border-radius: 4px;
    border: 1px solid $primary-color;

    @include flex;
    @include align-items(center);
    @include justify-content(center);
    @include box-shadow(0px 1px 2px 0px rgba(16, 24, 40, 0.05));

    &:hover {
      background: #fff !important;
      color: $primary-color !important;
      opacity: 0.6;
    }

    svg {
      margin-right: 8px;
    }
  }

  .file-list-wrap {
    .document-item {
      margin-top: 16px;
      @include flex;
      @include justify-content(space-between);
      @include align-items(center);
    }

    .document-info {
      @include flex;

      .image {
        margin-right: 12px;
        width: 48px;
        height: 48px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .content {
        p {
          margin: 0;

          &.file-name {
            color: $primary-color;
          }

          &.category-name {
            color: #191919;
          }
        }
      }
    }
  }
}
