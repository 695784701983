@import '../../../mixins.scss';

.shared-hardware-scan-qrcode-screen {
  h2 {
    margin: 0;
    color: $grey-color;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.48px;
  }
}
