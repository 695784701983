.app-notification-container {
  @apply tw-rounded-lg tw-shadow-primary tw-p-4 tw-relative;

  &:before {
    content: '';
    @apply tw-absolute tw-left-0 tw-top-0 tw-w-1.5 tw-h-full;
  }

  &.ant-notification-notice-error {
    @apply tw-bg-error-100;
  }

  &.ant-notification-notice-success {
    @apply tw-bg-primary-100;

    &:before {
      @apply tw-bg-success-500;
    }
  }

  &.ant-notification-notice-warning {
    @apply tw-bg-warning-100;

    &:before {
      background: #c27329;
    }
  }

  .ant-notification-notice-icon {
    line-height: 16px !important;
    margin-top: 2px !important;
  }

  .ant-notification-notice-message,
  .ant-notification-notice-description {
    margin-inline-start: 20px !important;

    p {
      @apply tw-m-0;
    }
  }

  .ant-notification-notice-close {
    top: 8px !important;
    inset-inline-end: 5px !important;
  }
}
