@import '../../mixins.scss';

.attachment-uploader-container.ant-upload-wrapper {
  .ant-upload-drag {
    background: #fff;
    border: 1px dashed $primary-color;
    @include border-radius(2px);

    &:hover {
      border: 1px dashed $primary-color;
    }

    .ant-upload {
      @include flex;
      @include align-items(center);
      @include justify-content(center);

      &.ant-upload-btn {
        padding: 0;
      }
    }

    .ant-upload-hint {
      @include flex;
      @include justify-content(center);
      @include align-items(center);
      margin: 0;
      color: #4b4b4b;
      text-align: center;
      font-family: 'Oxygen', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      svg {
        margin-right: 8px;
        width: 20px;
      }
    }

    .ant-upload-list-item-container {
      margin-bottom: 0;
    }

    &.ant-form-item-has-success {
      .ant-upload-list-item {
        width: 100%;
      }

      .ant-upload-list.ant-upload-list-picture-card {
        margin-top: 8px;
        border-radius: 6px;
        border: 1px solid $border-color;
        background: #fff;
        padding: 6px 9px !important;
      }
    }

    &.ant-upload-disabled {
      border: 1px dashed $border-color;

      .ant-upload-drag-icon {
        svg {
          fill: $border-color !important;
        }
      }

      .ant-upload-text {
        color: $border-color !important;
      }
    }
  }

  .ant-upload-drag-icon {
    margin: 0 !important;
    svg {
      fill: $primary-color;
    }
  }

  .ant-upload-text {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $primary-color !important;
  }

  .ant-upload-drag-container {
    margin: 24px 0;
  }

  .ant-upload-wrapper {
    width: 100%;

    &.ant-upload-picture-card-wrapper {
      .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-name {
        display: block;
      }
    }

    .ant-upload-list {
      .ant-upload-list-item .ant-upload-list-item-name {
        overflow: visible;
      }

      &.ant-upload-list-picture {
        .ant-upload-list-item {
          height: 60px;
          padding: 4px;
          @include align-items(center);

          .ant-upload-list-item-thumbnail {
            image {
              object-fit: contain;
            }
          }
        }
      }
    }

    .ant-upload-drag .ant-upload {
      height: auto;
    }
  }
}
