@import '../../mixins.scss';

.login-form-container {
  width: 100%;
  height: 100%;
  background-image: url(../../images/auth/login-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  @include flex;
  @include align-items(center);
  @include justify-content(center);

  .form-container {
    background-color: #fff;
    padding: 56px;
    width: 468px;

    @include border-radius(8px);
  }

  .logo-section {
    width: 280px;
    margin: 23px auto 56px auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .ant-input-affix-wrapper {
    padding: 0px 11px !important;
  }

  .ant-input {
    height: 38px !important;
    padding: 4px 11px;
    border-radius: 8px !important;
  }

  .verify-section {
    .ant-checkbox-wrapper {
      color: $grey-color;
      font-family: 'Oxygen', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;

      &:hover {
        .ant-checkbox-inner {
          border-color: $primary-color;
        }
      }
    }
  }

  .forgot-password-section {
    margin-top: 12px;
    margin-bottom: 32px;
    text-align: center;

    a {
      color: $primary-color;
      font-family: 'Oxygen', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
  }

  .FormFooter {
    margin-top: 32px;
    button {
      font-size: 14px;
      line-height: 22px;
      padding: 8px 12px;
    }
  }

  .ant-form-item-with-help.password-input .ant-form-item-explain div,
  .ant-form-item-with-help.password-input .ant-form-item-explain .ant-form-item-explain-error,
  .ant-form-item-with-help.password-input .ant-form-item-explain .ant-form-item-explain-success {
    top: 40px;
  }

  @media (max-width: 767px) {
    .container {
      width: 100%;
    }

    .form-container {
      width: 100%;
    }
  }

  @media (max-width: 575px) {
    .form-container {
      padding-left: 24px;
      padding-right: 24px;
    }

    .logo-section {
      width: 230px;
    }
  }
}

.ant-modal-wrap.verify-login-modal {
  .anticon.anticon-exclamation-circle {
    display: none;
  }

  .ant-modal-confirm-content {
    margin-left: 0px !important;
  }

  .ant-modal-confirm-confirm .ant-modal-content .ant-modal-body {
    padding: 48px 32px;
  }

  .ant-modal-confirm .ant-modal-confirm-paragraph {
    max-width: 100% !important;
  }

  .confirm-content {
    h2 {
      margin: 0;
      margin-bottom: 12px;
      color: #305a20;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.4px;
    }

    p {
      margin: 0;
      color: $grey-color;
      font-family: 'Oxygen', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }
  }

  .ant-modal-confirm-btns {
    margin-top: 32px;

    button {
      flex: 1;
      padding: 9px 12px;
      color: $primary-color;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
