@import '../mixins.scss';

.component-hardware-camera-not-ready-container {
  padding: 24px;
  text-align: center;

  h2 {
    margin-top: 12px;
    margin-bottom: 12px;
    color: $danger-color;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.4px;
  }

  p {
    width: 244px;
    margin: 0 auto;
    color: $grey-color;
    text-align: center;
    font-family: 'Oxygen', sans-serif;
  }

  div {
    width: 100%;
    margin-top: 12px;
    @include flex;
    @include justify-content(center);
  }
}
