@import '../../../mixins.scss';

.product-form-container {
  .pair-tagging {
    margin-bottom: 16px;
    p {
      margin: 0;
      color: #191919;
      font-size: 12px;
      line-height: 16px;
    }

    span {
      margin: 0;
      color: #898989;
      font-size: 10px;
      line-height: 14px;
      display: inline-block;
    }

    .tagging-form-field {
      margin-top: 8px;
      padding: 12px;
      border: 1px solid #dad9d9;
      background: #f8f8f8;
      @include border-radius(8px);

      & > span {
        margin-bottom: 4px;
        color: #898989;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  p.tagging-label {
    width: calc(100% - 28px);
    @include flex;

    span {
      flex: 1;
    }
  }

  .flex-2-column {
    gap: 8px;
    @include flex;

    & > div {
      flex: 1;
    }
  }

  .row-2-column {
    gap: 8px;
    margin-bottom: 16px;
    @include flex;
    @include justify-content(space-between);

    & > span {
      flex: 1;
    }

    div.field-item {
      width: 100%;
      gap: 8px;
      @include flex;

      .ant-form-item {
        flex: 1;
        margin-bottom: 0;
      }
    }
  }

  .delete-tagging-button {
    align-self: center;
    width: 28px;

    button {
      color: $danger-color;
      padding: 0 !important;
      border: none !important;

      &:hover {
        color: $danger-color !important;
      }
    }
  }

  .add-more-btn {
    color: $primary-color;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    span {
      color: $primary-color;
    }
  }

  .attachment-list-section {
    .upload-attachment-button-container {
      flex-wrap: wrap;
      @include flex;

      & > button.btn-upload-attachment {
        flex: 0 1 calc(33.33% - 16px);
        min-width: 200px;
      }

      .file-list-wrap {
        width: 100%;
        flex-wrap: wrap;
        gap: 16px;
        @include flex;

        .document-item {
          margin-top: 12px !important;
          flex: 0 1 calc(33.33% - 16px);
          padding: 8px 12px;
          border: 1px solid #dad9d9;
          background: #fff;
          @include border-radius(6px);
        }
      }
    }
  }
}
