.user-form-container {
  .partner-entity-wrap {
    margin-bottom: 16px;

    p.label {
      color: #191919;
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
      margin-bottom: 4px;
    }

    p.value {
      margin: 0;
      color: #191919;
      font-family: 'Oxygen', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
  }
}
