@import '../../mixins.scss';

.component-hardware-register-manual-step {
  h2 {
    margin: 0;
    color: $grey-color;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.48px;
  }

  form {
    padding: 24px;
    border: 1px solid $border-color;
    background: #fff;
    @include border-radius(8px);
    @include box-shadow(0px 0px 10px 0px rgba(130, 134, 139, 0.2));

    .form-container .form-content {
      margin-top: 0;
      margin-bottom: 24px;
    }

    button.btn-primary {
      padding: 12px;
      width: 100%;
      font-weight: 500;
    }
  }
}
