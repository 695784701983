@import '../mixins.scss';

.dropdown-app-container {
  border-radius: 12px;
  background: #fff;
  @include box-shadow(0px 20px 25px -5px rgba(0, 0, 0, 0.1));

  &.large {
    min-width: 412px !important;
    li.ant-dropdown-menu-item {
      margin-bottom: 12px !important;
    }
  }

  &.small {
    min-width: 200px !important;
    li.ant-dropdown-menu-item {
      margin-bottom: 8px !important;
    }
  }

  &.medium {
    min-width: 277px !important;
    li.ant-dropdown-menu-item {
      margin-bottom: 8px !important;
      padding: 0;
    }
  }

  .ant-dropdown-menu {
    padding: 32px 24px;
  }

  &.secondary {
    li.ant-dropdown-menu-item {
      padding: 12px !important;
      @include border-radius(4px);

      &:hover {
        background: $primary-50-color;
        border-color: $primary-50-color;
      }
    }
  }

  &.primary {
    .dropdown-item {
      padding: 12px !important;
      background: #fff;
      border-color: #fff;
      @apply tw-text-left tw-border-none;
      @include border-radius(4px);

      &.active {
        background: $primary-50-color;
        border-color: $primary-50-color;
      }
    }

    li.ant-dropdown-menu-item {
      &:hover {
        background: $primary-50-color;
        border-color: $primary-50-color;

        .dropdown-item {
          background: $primary-50-color;
          border-color: $primary-50-color;
        }
      }

      .ant-dropdown-menu-title-content > a::after {
        position: static;
      }
    }
  }

  li.ant-dropdown-menu-item {
    &.ant-dropdown-menu-item-disabled {
      @apply tw-bg-grey-100;

      &:hover {
        @apply tw-bg-grey-100;
      }
    }
  }

  li.ant-dropdown-menu-item {
    &:last-child {
      margin-bottom: 0px !important;
    }
  }

  @media (max-width: 479px) {
    &.large {
      min-width: 280px !important;
    }
  }
}

.app-dropdown-container {
  &.disabled {
    border-color: #d9d9d9 !important;
    background: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }

  &.primary {
    @apply tw-bg-primary-500 tw-text-white;
  }

  &.secondary {
    @apply tw-bg-white tw-text-primary-500 tw-border-solid tw-border tw-border-primary-500;

    &:hover {
      @apply tw-bg-primary-800 tw-text-white;
    }
  }

  &.ant-dropdown-open {
    &.secondary {
      @apply tw-bg-primary-800 tw-text-white;
    }
  }
}

.filter-dropdown-button {
  & > button:hover {
    background: $primary-800-color !important;
    color: #fff !important;
  }
  &.ant-dropdown-open {
    & > button {
      @apply tw-bg-primary-800 tw-text-white hover:tw-bg-white;
    }

    &:hover > button {
      @apply tw-bg-primary-800 tw-text-white hover:tw-bg-white;
    }
  }
}
