@import '../../../mixins.scss';

.role-filter-content {
  h2 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #484848;
  }

  .ant-checkbox-group {
    flex-direction: column;
  }

  .ant-form-item.search-box {
    input {
      min-height: 32px;
    }

    .ant-input-outlined:focus-within {
      border-color: $primary-color;
    }
  }

  .form-footer {
    button {
      width: 100%;
    }
  }
}
