@import '../../mixins.scss';

#pages-hardware-filling-new-container {
  .footer-section {
    margin-left: -16px;
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    border-bottom: 1px solid #dad9d9;
    z-index: 1;
    @include flex;
    @include justify-content(center);
    @include box-shadow(0px 0px 10px 0px rgba(130, 134, 139, 0.2));

    & > div {
      width: 672px;
      padding: 24px;
      gap: 10px;
      margin-left: -210px;
      @include flex;

      button {
        flex: 0.5 1;

        &:last-child {
          flex: 1;
          padding: 12px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .footer-section {
      & > div {
        margin-left: -5px;
      }
    }
  }

  @media (max-width: 767px) {
    .footer-section {
      & > div {
        width: 100%;
        button {
          &:first-child:not(.btn-primary) {
            flex: 1;
            min-width: auto;
          }

          &:first-child.btn-secondary {
            flex: 1;
            min-width: auto;
          }

          &:last-child {
            flex: 1;
            min-width: auto;
          }
        }
      }
    }
  }
}
