@import '../../mixins.scss';

.product-history-attachment-container {
  .hardware-attachment-tabs {
    .ant-tabs-tab {
      .tab-title {
        color: #898989;
        font-size: 16px;
        line-height: 24px;
        gap: 4px;
        @include flex;
        @include align-items(center);
      }

      &.ant-tabs-tab-active {
        .tab-title {
          color: $primary-800-color;
          font-weight: 600;
        }
      }
    }

    .ant-tabs-ink-bar {
      background: $primary-color;
    }
  }

  .partner-history-container {
    .content-wrap {
      padding: 0;
      @include box-shadow(none);
    }

    .page-content-header {
      display: none;
    }
  }
}
