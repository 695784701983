.report-top-section-dropdown {
  ul.ant-dropdown-menu {
    @apply tw-bg-none tw-shadow-none;

    .ant-dropdown-menu-item {
      &:hover {
        @apply tw-bg-primary-50;
      }
    }
  }
}

.report-top-action-button.ant-dropdown-trigger {
  &.ant-dropdown-open {
    @apply tw-bg-primary-800;
  }
}
