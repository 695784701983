@import '../../mixins.scss';
.role-collapse-detail-container {
  .ant-collapse {
    border: none !important;

    .ant-collapse-item {
      border-bottom: none !important;

      .ant-collapse-header {
        padding: 12px;
        background: #f0f0f0;

        .ant-collapse-expand-icon {
          height: 14px;
        }

        .ant-collapse-header-text {
          color: #4b4b4b;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 15px;
          letter-spacing: 0.24px;
          text-transform: uppercase;
        }
      }

      .ant-collapse-content-box {
        padding: 4px 0;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 0 !important;
    border-bottom: 1px solid #e3e3e3;

    &:last-child {
      border-bottom: 0;
    }
  }

  .ant-checkbox-group {
    &.market-checkbox {
      @include flex;
      width: 100%;
      flex-direction: column;
      padding-right: 12px;
      padding-left: 12px;

      label.ant-checkbox-wrapper {
        @include flex;
        @include align-items(center);
        @include justify-content(center);
        padding: 12px;
        position: relative;
        border-bottom: 1px solid #e3e3e3;

        & > span + span {
          padding-right: 0;
          padding-left: 0;
          font-family: 'Oxygen', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          position: absolute;
          left: 12px;
        }
      }

      @media (max-width: 479px) {
        label.ant-checkbox-wrapper {
          @include justify-content(flex-end);
        }
      }
    }
  }

  .ant-checkbox-wrapper {
    &.ant-checkbox-wrapper-checked {
      .ant-checkbox-inner {
        background-color: #c4c4c4 !important;
        border-color: #c4c4c4;
      }
    }
    .ant-checkbox {
      &:hover {
        .ant-checkbox-inner {
          border-color: #d9d9d9 !important;
        }
      }
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: #d9d9d9 !important;
      }
    }
  }

  .role-actions-section.general-collapse {
    @include flex;
    @include align-items(center);
    padding: 3px 12px;
    border-bottom: 1px solid #e3e3e3;

    .label-side {
      flex: 1;
      width: 160px;
    }

    .actions-side {
      flex: 1 0 auto;
      width: auto;
    }
  }

  .subtitle-collapse {
    border-bottom: 1px solid #e3e3e3;
    line-height: 20px;
    height: 47px;

    @include flex;
    @include align-items(center);
  }
}
