@import '../../mixins.scss';

.e-input-number-container {
  padding-inline-end: 8px;
  .ant-input-number-suffix {
    padding-right: 67px;
  }

  .ant-input-number-handler-wrap {
    flex-direction: row-reverse;
    opacity: 1;
    width: 64px;
    @include align-items(center);

    .ant-input-number-handler {
      height: 100%;
      border: none;
    }
  }
  .ant-input-number-handler-down-inner,
  .ant-input-number-handler-up-inner {
    color: #64aa4b;
  }
}
