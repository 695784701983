@import '../../../mixins.scss';

.component-verify-filter-form {
  background: #fff;
  margin-top: -1px;
  @include flex;
  @include justify-content(center);

  .form-wrap {
    width: 632px;
    padding: 0 24px;

    form {
      width: 100%;
    }

    .form-content {
      width: 100%;
    }
  }

  p.label-wrap {
    margin-top: 16px;
    margin-bottom: 8px;
    width: 100%;

    @include flex;
    @include justify-content(space-between);
    @include align-items(center);

    span {
      color: $grey-color;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      gap: 8px;
      @include flex;
      @include justify-content(space-between);
      @include align-items(center);

      &.hardware-scanned-text {
        color: $grey-color;
      }

      &.invalid-hardware-text {
        color: $danger-color;

        & > span {
          color: $danger-color;
        }
      }
    }
  }

  .form-content {
    flex-direction: column;
  }

  .seach-wrap {
    gap: 12px;
    margin-bottom: 16px;
    width: 100%;
    @include flex;

    div.ant-form-item.search-box {
      flex: 1;
      margin-bottom: 0;
      margin-right: 0 !important;
    }

    button {
      flex: 0 0 auto;
    }
  }

  @media (max-width: 530px) {
    .form-wrap {
      padding: 0 12px;
    }

    .seach-wrap {
      .ant-form-item {
        min-width: auto !important;
      }
    }

    p.label-wrap {
      span {
        font-size: 10px;
        gap: 4px;
      }
    }
  }
}
