.history-drawer {
  .ant-drawer-header {
    border-bottom: none;
    @apply tw-pt-8 tw-pb-0 tw-px-6;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
    font-size: 14px;

    button.ant-drawer-close {
      margin-right: 0;
      @apply tw-self-start;
    }

    .ant-drawer-title {
      @apply tw-block;
    }
  }
}
