@import '../../../mixins.scss';

.site-form-container {
  .attachment-category-wrap {
    border: 1px solid $border-color;
    padding: 8px;
    @include border-radius(8px);

    p {
      margin-bottom: 16px;
      margin-top: 0;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
    }
  }
}
