@import '../../mixins.scss';

.tracking-information-container {
  .type-section {
    gap: 2px;
    @include flex;
    @include align-items(center);
  }

  .progress-bar-wrap {
    margin-bottom: 16px;
    p {
      margin: 0;
      font-size: 10px;
      line-height: 14px;
      @include flex;
      @include justify-content(flex-end);
    }
  }

  .ant-progress {
    margin-bottom: 3px;
    .ant-progress-outer {
      @include flex;
      flex-direction: column;
      padding-inline-end: 0;
    }

    .ant-progress-inner {
      background: #e3e3e3;
    }

    .ant-progress-bg {
      background: $primary-color;
    }
  }

  @media (max-width: 767px) {
    .flex-row {
      flex-direction: column;
    }
  }
}
