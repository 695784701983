@import '../../mixins.scss';

.component-hardware-filling-scan-step {
  .wrapper {
    width: 672px;
    margin: 0 auto;
    padding: 24px;
    margin-bottom: 70px;
  }

  .scan-screen-data {
    margin-bottom: 24px;

    p.label {
      color: #898989;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    div.card-item {
      margin: 0 auto;
      padding: 12px 16px;
      min-width: 230px;
      max-width: 250px;
      border-radius: 8px;
      background: #fff;
      @include box-shadow(0px 0px 5px 0px rgba(130, 134, 139, 0.3));
    }
  }

  h2 {
    margin: 0;
    color: $grey-color;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.48px;
  }

  .ant-tabs-nav {
    .ant-tabs-nav-list {
      width: 100%;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid $border-color;
      background: #fff;

      @include flex;
      @include justify-content(space-between);

      .ant-tabs-tab {
        flex: 1;
        margin: 0;
        padding: 6px 12px;
        color: $secondary-text;
        font-size: 14px;
        line-height: 20px;
        font-family: 'Poppins', sans-serif;
        @include justify-content(center);

        &.ant-tabs-tab-active {
          background-color: $primary-50-color;
          @include border-radius(8px);

          .ant-tabs-tab-btn {
            color: #437132;

            span {
              color: $grey-color;
            }
          }
        }

        .ant-tabs-tab-btn {
          flex-direction: column;
          @include flex;
          @include align-items(center);

          svg {
            margin-bottom: 8px;
          }
        }
      }

      .ant-tabs-ink-bar {
        display: none;
      }
    }
  }

  p.notice-section {
    max-width: 500px;
    margin: 16px auto;
    color: $grey-color;
    font-size: 12px;
    line-height: 16px;
    gap: 12px;
    @include flex;
    @include justify-content(center);

    & > svg {
      min-width: 14px;
    }

    ul {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 20px;
    }
  }

  form {
    padding: 24px;
    border: 1px solid $border-color;
    background: #fff;
    @include border-radius(8px);
    @include box-shadow(0px 0px 10px 0px rgba(130, 134, 139, 0.2));

    .form-container .form-content {
      margin-top: 0;
      margin-bottom: 24px;
    }

    button.btn-primary {
      padding: 12px;
      width: 100%;
      font-weight: 500;
    }
  }

  @media (max-width: 767px) {
    .wrapper {
      width: 100%;
      padding: 24px 0;
    }
  }

  @media (max-width: 479px) {
    .ant-tabs-nav {
      .ant-tabs-nav-list {
        padding: 6px;

        .ant-tabs-tab {
          font-size: 12px;
          .ant-tabs-tab-btn {
            svg {
              width: 18px;
              height: 18px;
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
}
