@import '../mixins.scss';

.notice-custom {
  position: relative;
  padding: 16px;
  background: #ecf2f4;
  border: none;
  overflow: hidden;
  align-items: flex-start;
  @include border-radius(8px);
  @include box-shadow(0px 0px 10px 0px rgba(130, 134, 139, 0.2));

  & > svg:not(.anticon-close) {
    margin-top: 3px;
    margin-right: 16px;
  }

  &:before {
    content: '';
    width: 6px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  p {
    margin: 0;
  }

  a {
    margin-top: 8px;
    padding: 4px 12px;
    border: 1px solid #dad9d9;
    background: #fff;
    color: #191919;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    gap: 8px;
    @include border-radius(4px);
    @include inline-flex;
    @include align-items(center);

    &:hover {
      color: #191919;
      background: #fff;
    }
  }

  &.error {
    background: #fff;
  }

  &.info {
    color: #3f7990 !important;

    &:before {
      background: #3f7990;
    }
  }

  &.warning {
    background: #fdf4dc;

    &:before {
      background: #c27329;
    }
  }

  .ant-alert-message {
    margin-bottom: 0;
  }

  .ant-alert-content {
    .ant-alert-description {
      color: $grey-color;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
