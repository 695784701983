@import '../../mixins.scss';

.menu-drawer-container {
  .ant-drawer-mask {
    background: transparent;
  }

  .ant-drawer-header {
    position: absolute;
    top: 20px;
    right: 8px;
    padding: 0;
    border-bottom: none;

    button {
      margin: 0;
      padding: 0;
      line-height: 0;
      z-index: 1;
    }
  }

  .ant-drawer-body {
    padding: 0;
    flex-direction: column;
    @include flex;
    @include justify-content(space-between);

    .logo-wrap {
      width: 140px;
      padding-left: 16px;
      padding-top: 16px;

      & > div {
        line-height: 0;
      }
    }
  }
}
