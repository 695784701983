.product-trace-update-status-container {
  .ant-radio-group {
    .ant-radio-wrapper {
      @apply tw-p-3 tw-rounded-lg tw-bg-white;
      &.ant-radio-wrapper-checked {
        @apply tw-bg-primary-100;
      }
      .ant-radio.ant-wave-target {
        @apply tw-self-start;
      }
    }
  }
}
