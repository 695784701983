@import '../../mixins.scss';
.btn-primary-icon {
  @include flex;
  @include justify-content(center);
  @include align-items(center);
  @include border-radius(2px);
  border: 1px solid $primary-color !important;
  color: $primary-color;
  background: #fff;
  padding: 9px !important;

  &:hover {
    border-color: $primary-color !important;
    color: $primary-color !important;
    box-shadow: none;
    opacity: 0.8;
    background: #fff !important;

    &:disabled {
      border-color: #d9d9d9 !important;
      background: #d9d9d9 !important;
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }

  &:disabled {
    border-color: #d9d9d9 !important;
    background: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }

  &.secondary-color {
    border: 1px solid $secondary-color !important;
    color: $secondary-color;
  }

  &.danger-color {
    border: 1px solid $danger-color !important;
    color: $danger-color;
  }

  &.btn-icon {
    svg {
      margin-left: 0;
    }
  }
}

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: $primary-color !important;
}
