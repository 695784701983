@import '../../mixins.scss';

.btn-back {
  @include flex;

  &:hover {
    border-color: $primary-color !important;
    color: $primary-color !important;
    box-shadow: none;
    opacity: 0.8;
  }

  svg {
    margin-right: 8px;
  }
}
