@import '../../mixins.scss';

.update-status-form-container {
  .ant-radio-group {
    width: 100%;
    .ant-radio-wrapper {
      padding: 12px;
      width: 100%;
      @include border-radius(8px);

      .ant-radio + * {
        margin-left: 12px;
        padding: 0;
        line-height: 1;

        span.status {
          position: relative;
          padding-left: 12px;
          &::before {
            content: '';
            width: 8px;
            height: 8px;
            position: absolute;
            left: 0px;
            top: 7px;
            background: $border-color;
            @include border-radius(5px);
          }

          &.active {
            &::before {
              background: $active-color;
            }
          }

          &.inactive {
            &::before {
              background: $inactive-color;
            }
          }

          &.idle {
            &::before {
              background: $pending-color;
            }
          }
        }
      }

      &:hover,
      &.ant-radio-wrapper-checked {
        background: $primary-50-color;
      }
    }
  }
}
