@import '../../mixins.scss';

.component-hardware-filling-review-step {
  form {
    width: 680px;
    margin: 16px auto 95px;

    p.label {
      margin: 0;
      margin-bottom: 4px;
      color: $grey-color;
      font-size: 12px;
      line-height: 16px;
    }

    .ant-picker {
      width: 100%;
    }

    .ant-input-number {
      width: 100%;
    }
  }

  .flex-row {
    gap: 2.4rem;
    @include flex;

    & > div {
      flex: 1;
    }

    @media (max-width: 669px) {
      flex-direction: column;
    }
  }

  .divider {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .ant-btn-default.btn-confirm {
    border-color: $primary-color;
    background: $primary-color;
    color: #fff;

    &:hover {
      border-color: $primary-color;
      background: $primary-color;
      color: #fff;
      opacity: 0.8;
    }
  }

  @media (max-width: 767px) {
    form {
      width: 100%;
    }
  }
}
