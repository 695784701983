@import '../mixins.scss';

span.common-status {
  padding: 4px 8px 4px 24px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  position: relative;
  text-transform: capitalize;
  display: inline-block;
  @include border-radius(24px);

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    left: 9px;
    top: 7px;
    @include border-radius(5px);
  }

  &.active {
    background-color: $active-bg-color;
    &::before {
      background: $active-color;
    }
  }

  &.good,
  &.resolved {
    border: 1px solid $border-color;
    background: #f8f8f8;
    &::before {
      background: $active-color;
    }
  }

  &.pending {
    background-color: $pending-bg-color;
    &::before {
      background: $pending-color;
    }
  }

  &.idle {
    background-color: $pending-bg-color;
    &::before {
      background: $pending-color;
    }
  }

  &.inactive,
  &.nonActive {
    background-color: $inactive-bg-color;
    &::before {
      background: $inactive-color;
    }
  }

  &.faulty,
  &.unresolved {
    border: 1px solid $border-color;
    background: #f8f8f8;
    &::before {
      background: $inactive-color;
    }
  }

  &.request {
    background-color: $request-bg-color;
    &::before {
      background: $request-color;
    }
  }
}
