@import '../../../mixins.scss';

.shared-hardware-progress-bar {
  border-bottom: 1px solid #dad9d9;
  background: #fff;
  padding: 16px 0;

  button.btn-back-action {
    margin-left: 12px;
  }

  h2 {
    margin-bottom: 16px;
    margin-top: 0;
    color: #484848;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.4px;
  }

  .status-bar {
    @include flex;
    @include justify-content(center);
  }

  ul {
    list-style: none;
    width: 672px;
    padding-left: 0;
    margin: 0;

    @include flex;
    @include align-items(center);
    @include justify-content(space-between);

    li {
      flex: 1;
      position: relative;
      flex-direction: column;
      @include flex;
      @include align-items(center);

      &:after {
        content: '';
        position: absolute;
        background: #969696;
        height: 1px;
        width: calc(100% - 22px);
        top: 11px;
        left: 95px;
      }

      &:last-child {
        &::after {
          display: none;
        }

        span.number {
          &::after {
            display: none;
          }
        }
      }

      &.for3step {
        &::after {
          left: 123px;
        }
      }

      &.for5step {
        &::after {
          left: 78px;
        }
      }

      &.for6step {
        &::after {
          left: 67px;
        }
      }

      span.number {
        margin-bottom: 4px;
        width: 24px;
        height: 24px;
        border-radius: 100px;
        border: 1px solid #969696;
        color: #969696;
        text-align: center;
        font-family: 'Oxygen', sans-serif;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        @include flex;
        @include align-items(center);
        @include justify-content(center);
      }

      span.text {
        color: #969696;
        font-size: 12px;
        line-height: 16px;
      }

      &.active {
        span.number {
          background: #64aa4b;
          border: 1px solid #64aa4b;
          color: #fff;
        }

        span.text {
          font-weight: 600;
          color: #4b4b4b;
        }
      }

      &.completed {
        span.number {
          background: #0a7a08;
          border: 1px solid #0a7a08;
          color: #fff;
        }

        span.text {
          font-weight: 600;
          color: #4b4b4b;
        }
      }

      &.disabled {
        span.number {
          background: #969696;
          border-color: #969696;
        }
      }
    }
  }

  @media (max-width: 704px) and (min-width: 480px) {
    ul {
      width: 430px;

      li {
        span.text {
          min-height: 32px;
        }
        &::after {
          left: 65px;
        }

        &.for3step {
          &::after {
            left: 83px;
          }
        }

        &.for5step {
          &::after {
            left: 54px;
          }
        }

        &.for6step {
          &::after {
            left: 47px;
          }
        }
      }
    }
  }

  @media (max-width: 479px) {
    ul {
      width: 287px;
      li {
        span.text {
          min-height: 32px;
        }
        &::after {
          left: 59px;
        }

        span.text {
          font-size: 8px;
        }

        &.for3step {
          &::after {
            left: 59px;
          }
        }

        &.for4step {
          &::after {
            left: 46px;
          }
        }

        &.for5step {
          &::after {
            left: 40px;
          }
        }

        &.for6step {
          &::after {
            left: 35px;
          }

          span.text {
            font-size: 7px;
          }
        }
      }
    }
  }
}
