$primary-color: #64aa4b;
$primary900-color: #224a13;
$secondary-color: #0483c3;
$error-color: #b13939;
$danger-color: #b13939;
$warning-color: #edc346;
$success-color: #0a7a08;
$grey-color: #191919;
$border-color: #dad9d9;
$pending-color: #f29d41;
$pending-bg-color: #fdf4dc;
$active-color: #0a7a08;
$active-bg-color: #e0eedb;
$inactive-color: #b13939;
$inactive-bg-color: #fcecea;
$request-bg-color: #e1f4fc;
$request-color: #0483c3;
$secondary-text: #898989;
$primary-300-color: #acd49e;
$primary-50-color: #e0eedb;
$primary-900-color: #224a13;
$primary-800-color: #437132;

@mixin flex {
  display: -webkit-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

@mixin inline-flex {
  display: -webkit-inline-flex;
  display: -ms-inline-flex;
  display: -o-inline-flex;
  display: inline-flex;
}

@mixin align-items($position) {
  -webkit-align-items: $position;
  -ms-align-items: $position;
  -o-align-items: $position;
  align-items: $position;
}

@mixin align-self($position) {
  -webkit-align-self: $position;
  -ms-align-self: $position;
  -o-align-self: $position;
  align-self: $position;
}

@mixin justify-content($position) {
  -webkit-justify-content: $position;
  -ms-justify-content: $position;
  -o-justify-content: $position;
  justify-content: $position;
}

@mixin flex-direction($position) {
  -webkit-flex-direction: $position;
  -ms-flex-direction: $position;
  -o-flex-direction: $position;
  flex-direction: $position;
}

@mixin flex-wrap($value) {
  -webkit-flex-wrap: $value;
  -ms-flex-wrap: $value;
  -o-flex-wrap: $value;
  flex-wrap: $value;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin transform($value) {
  -webkit-transform: $value;
  -moz-transform: $value;
  -ms-transform: $value;
  -o-transform: $value;
  transform: $value;
}

@mixin border-radius($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  -ms-border-radius: $value;
  border-radius: $value;
}

@mixin box-shadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  -ms-box-shadow: $value;
  box-shadow: $value;
}

@mixin border-top-right-radius($value) {
  -webkit-borde-top-right-radius: $value;
  -moz-border-top-right-radius: $value;
  -ms-border-top-right-radius: $value;
  border-top-right-radius: $value;
}

@mixin border-bottom-right-radius($value) {
  -webkit-borde-bottom-right-radius: $value;
  -moz-border-bottom-right-radius: $value;
  -ms-border-bottom-right-radius: $value;
  border-bottom-right-radius: $value;
}

@mixin border-top-left-radius($value) {
  -webkit-borde-top-left-radius: $value;
  -moz-border-top-left-radius: $value;
  -ms-border-top-left-radius: $value;
  border-top-left-radius: $value;
}

@mixin border-bottom-left-radius($value) {
  -webkit-borde-bottom-left-radius: $value;
  -moz-border-bottom-left-radius: $value;
  -ms-border-bottom-left-radius: $value;
  border-bottom-left-radius: $value;
}
