@import '../../mixins.scss';

.component-hardware-register-choose-type-step {
  .wrapper {
    width: 672px;
    margin: 0 auto;
    padding: 24px;
  }

  .notice-custom {
    width: 500px;
    margin: 0 auto;
  }

  p.choose-type-text {
    margin-top: 24px;
    margin-bottom: 24px;
    color: $grey-color;
    text-align: center;
    font-family: 'Oxygen', sans-serif;
    font-size: 16px;
    line-height: 24px;
  }

  .type-buttons-section {
    flex-wrap: wrap;
    gap: 12px;

    @include flex;
    @include justify-content(space-between);

    button {
      flex: 1;
      min-width: 294px;
      padding: 10px 8px;
      border: none;
      @include flex;
      @include align-items(center);
      @include justify-content(flex-start);
      @include box-shadow(0px 0px 5px 0px rgba(130, 134, 139, 0.3));
      @include border-radius(8px);

      &:hover,
      &.selected {
        background: $primary-50-color !important;
        color: $grey-color !important;
        border: none;
      }

      &:focus-visible {
        outline: none;
        outline-offset: 0;
        transition: none;
      }

      .image-section {
        margin-right: 12px;
        width: 76px;
        height: 76px;
        background-color: rgb(239, 239, 239);
        @include border-radius(4px);

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .continue-button {
    width: 300px;
    margin-top: 48px;
    margin-left: auto;
    margin-right: auto;

    button {
      width: 100%;
      padding: 12px 24px;
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      background: $primary-color;
      @include border-radius(8px);
    }
  }

  @media (max-width: 769px) {
    .wrapper {
      width: 100%;
      padding: 24px 0;
    }

    .notice-custom {
      width: 398px;
    }
  }

  @media (max-width: 632px) {
    .type-buttons-section {
      button {
        min-width: 200px;
      }
    }
  }

  @media (max-width: 479px) {
    .type-buttons-section {
      flex-direction: column;
    }

    .notice-custom {
      width: 273px;
    }

    .continue-button {
      width: 100%;
    }
  }

  @media (max-width: 445px) {
    .type-buttons-section {
      button {
        min-width: 130px;
      }
    }
  }
}
