@import '../../mixins.scss';

.component-product-group-item-card {
  background: #fff;
  min-width: 176px;
  flex-direction: column;
  @include flex;
  @include justify-content(space-between);
  @include border-radius(8px);
  @include box-shadow(0px 0px 5px 0px rgba(130, 134, 139, 0.3));

  .item-error {
    color: $danger-color;
    font-family: 'Oxygen', sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
  }

  .status-section {
    gap: 2px;
  }

  .report-delete-section {
    padding: 8px 12px;
    border-top: 1px solid $border-color;

    @include flex;
    @include justify-content(space-between);
    @include align-items(center);

    p {
      margin: 0;
      line-height: 16px;
      font-weight: 500;

      a {
        gap: 4px;
        @include flex;
        @include align-items(center);
      }
    }

    button {
      color: #b13939 !important;
      min-height: 16px;

      & > svg {
        margin-right: 4px;
      }
    }
  }
}
