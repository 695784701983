@import '../../mixins.scss';

.filling-history-information-container {
  .flex-row {
    flex-wrap: wrap;
    gap: 16px;
    @include flex;

    & > div.item {
      flex: 0 1 calc(33.33% - 16px);
      min-width: 200px;
    }
  }

  .divider {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
