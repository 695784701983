@import '../../mixins.scss';

.hardware-card-item {
  position: relative;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(130, 134, 139, 0.3);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: flex-start;
  width: 200px;

  .item {
    // padding: 1rem;
    width: 100%;
    border: none;
    background: none;
    text-align: left;
  }

  p {
    margin: 0;
    min-height: 16px;
  }

  .btn-more-action {
    position: absolute;
    // width: 20px;
    // height: 20px;
    right: 17px;
    top: 17px;
    z-index: 10;
    border: none;
    background: none;
  }

  .card-info {
    width: 100%;
    height: 100%;
    flex-direction: column;

    @include flex;
    @include justify-content(space-between);

    h3.label {
      margin-top: 0;
      color: #898989;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }

    h2.card-title {
      margin-top: 5px;
      margin-bottom: 12px;
      color: $primary-color;
      font-family: 'Oxygen', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }

  .card-details,
  .card-date {
    p {
      color: $grey-color;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 8px;
      @include flex;
      @include align-items(center);

      svg {
        color: #898989;
      }

      span {
        margin-left: 5px;
      }
    }
  }

  .card-date {
    padding-top: 12px;
    padding-bottom: 0;
    border-top: 1px solid #ebe9f1;
    p {
      margin: 0;
    }
  }

  .type-section {
    color: #898989;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    gap: 2px;
    @include flex;
    @include align-items(center);
  }

  .status-section {
    @include flex;
    gap: 2px;
  }

  .report-delete-section {
    padding: 8px 12px;
    border-top: 1px solid $border-color;
    width: 100%;
    padding-bottom: 0;

    @include flex;
    @include justify-content(space-between);
    @include align-items(center);

    p {
      margin: 0;
      line-height: 16px;
      font-weight: 500;

      a {
        gap: 4px;
        @include flex;
        @include align-items(center);
      }
    }

    button {
      color: #b13939 !important;
      min-height: 16px;

      & > svg {
        margin-right: 4px;
      }
    }
  }

  .component-allocation-type {
    margin-bottom: 8px;
  }
}
