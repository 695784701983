@import '../../mixins.scss';

.main-menu {
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-right: none !important;

  li.ant-menu-item {
    margin: 0;
    width: 100%;
    height: auto !important;
    color: $grey-color;
    padding: 9px 12px !important;
    background: #fff;
    line-height: 20px;
    @include border-radius(0);
    @include align-items(center);

    &:hover,
    &.ant-menu-item-selected {
      background-color: $primary-300-color !important;
      @include border-radius(6px);
    }

    span.ant-menu-title-content {
      margin-left: 12px;
    }

    a {
      display: inline-block;
      white-space: pre-line;
      font-size: 14px;
      color: $grey-color;
      line-height: 20px;
      font-weight: 500;
      font-family: 'Poppins', sans-serif;
    }
  }

  .ant-menu-submenu {
    & > div.ant-menu-submenu-title {
      margin: 0;
      padding-left: 0px !important;
      padding-right: 12px;
      background: none;
      width: 100%;

      span {
        color: #969696;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
      }

      i.ant-menu-submenu-arrow {
        display: none;
      }
    }

    &.ant-menu-submenu-active {
      & > div.ant-menu-submenu-title {
        background: none;
      }
    }
  }
}
