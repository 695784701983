.ScanUpdateGroupCodeModal {
  .ant-modal-body {
    padding: 32px !important;
  }

  .ant-modal-close {
    width: 32px;
    height: 32px;
    top: 0;
    right: 0;
  }
}

.scanUpdateGroupCodeTabs {
  .ant-tabs-nav-list {
    @apply tw-w-full tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-3;

    & > div {
      @apply tw-flex-1 tw-justify-center;
    }

    .ant-tabs-ink-bar {
      @apply tw-hidden;
    }
  }
  .ant-tabs-nav {
    &:before {
      @apply tw-hidden;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    @apply tw-bg-primary-50 tw-rounded-xl;
  }
}
